import React from "react";
import { SEO_TYPE } from "../constant";

const SeoElement = props => {
  const { type, text, title, heading, to, label, list } = props;
  if (type === SEO_TYPE.INTERNAL_LINK) {
    // to, title, label
    return (
      <a href={to} title={title}>
        {label}
      </a>
    );
  } else if (type === SEO_TYPE.EXTERNAL_LINK) {
    // to, title, label
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" title={title}>
        {label}
      </a>
    );
  } else if (type === SEO_TYPE.PARAGRAPH) {
    // text
    return <p>{text}</p>;
  } else if (type === SEO_TYPE.UNORDERED_LIST) {
    // list
    return (
      <ul>
        {list.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  } else if (type === SEO_TYPE.H1) {
    // heading
    return <h1>{heading}</h1>;
  } else if (type === SEO_TYPE.H2) {
    // heading
    return <h2>{heading}</h2>;
  } else if (type === SEO_TYPE.H3) {
    // heading
    return <h3>{heading}</h3>;
  } else if (type === SEO_TYPE.H4) {
    // heading
    return <h4>{heading}</h4>;
  } else if (type === SEO_TYPE.H5) {
    // heading
    return <h5>{heading}</h5>;
  } else if (type === SEO_TYPE.H6) {
    // heading
    return <h6>{heading}</h6>;
  }
};

export default SeoElement;
