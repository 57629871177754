export const ELEMENT_TYPE = {
    TEXT_P: "TEXT_P",
    BUTTON_GATSBY_LINK: "BUTTON_GATSBY_LINK",
    H1: "H1",
    H2: "H2",
    H3: "H3"
};

export const SEO_TYPE = {
    INTERNAL_LINK: "INTERNAL_LINK",
    EXTERNAL_LINK: "EXTERNAL_LINK",
    PARAGRAPH: "PARAGRAPH",
    UNORDERED_LIST: "UNORDERED_LIST",
    H1: "H1",
    H2: "H2",
    H3: "H3",
    H4: "H4",
    H5: "H5",
    H6: "H6"
};

export default {ELEMENT_TYPE, SEO_TYPE};