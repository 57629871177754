/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

const schemaOrg = (
  siteUrl,
  facebookLink,
  linkedinLink,
  twitterLink,
  mediumLink,
  knowsLanguage,
  areaServed,
  defaultDescription,
  name,
  addressLocality1,
  addressPostCode1,
  addressStreet1,
  addressLocality2,
  addressPostCode2,
  addressStreet2,
  contactPhone,
  email,
  imageUrl,
  logoUrl,
  page1,
  page2,
  page3,
  page4,
  page5,
  page6
) => ({
  "@context": "http://schema.org",
  "@type": ["Organization", "ItemList"],
  url: siteUrl,
  sameAs: [linkedinLink, facebookLink, twitterLink, mediumLink],
  knowsLanguage: knowsLanguage,
  areaServed: areaServed,
  description: defaultDescription,
  name: name,
  legalName: name,
  image: {
    "@type": "ImageObject",
    url: imageUrl,
  },
  logo: logoUrl,
  address: [
    {
      "@type": "PostalAddress",
      addressLocality: addressLocality1,
      postalCode: addressPostCode1,
      streetAddress: addressStreet1,
    },
    {
      "@type": "PostalAddress",
      addressLocality: addressLocality2,
      postalCode: addressPostCode2,
      streetAddress: addressStreet2,
    },
  ],
  contactPoint: [
    {
      "@type": "ContactPoint",
      telephone: contactPhone,
      email: email,
      contactType: "customer service",
      areaServed: areaServed,
      availableLanguage: knowsLanguage,
    },
    {
      "@type": "ContactPoint",
      telephone: contactPhone,
      email: email,
      contactType: "technical support",
      areaServed: areaServed,
      availableLanguage: knowsLanguage,
    },
  ],
  itemListElement: [
    {
      "@type": "SiteNavigationElement",
      position: 1,
      name: page1.name,
      description: page1.description,
      url: siteUrl + page1.url,
    },
    {
      "@type": "SiteNavigationElement",
      position: 2,
      name: page2.name,
      description: page2.description,
      url: siteUrl + page2.url,
    },
    {
      "@type": "SiteNavigationElement",
      position: 3,
      name: page3.name,
      description: page3.description,
      url: siteUrl + page3.url,
    },
    {
      "@type": "SiteNavigationElement",
      position: 4,
      name: page4.name,
      description: page4.description,
      url: siteUrl + page4.url,
    },
    {
      "@type": "SiteNavigationElement",
      position: 5,
      name: page5.name,
      description: page5.description,
      url: siteUrl + page5.url,
    },
    {
      "@type": "SiteNavigationElement",
      position: 6,
      name: page6.name,
      description: page6.description,
      url: siteUrl + page6.url,
    },
  ],
});

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  image,
  pathname,
  locale,
}) {
  const { site, logoImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            twitterUsername
            facebookUsername
            linkedinUsername
            microData {
              siteUrl
              facebookLink
              linkedinLink
              twitterLink
              mediumLink
              knowsLanguage
              areaServed
              defaultDescription
              name
              addressLocality1
              addressPostCode1
              addressStreet1
              addressLocality2
              addressPostCode2
              addressStreet2
              contactPhone
              email
              imageUrl
              logoUrl
              page1 {
                name
                description
                url
              }
              page2 {
                name
                description
                url
              }
              page3 {
                name
                description
                url
              }
              page4 {
                name
                description
                url
              }
              page5 {
                name
                description
                url
              }
              page6 {
                name
                description
                url
              }
            }
          }
        }
        logoImage: file(relativePath: { eq: "largeIcon.png" }) {
          childImageSharp {
            fluid(maxWidth: 520) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  const siteUrl = `${site.siteMetadata.siteUrl}/${pathname || ""}`;
  const imageUrl = `${image ||
    site.siteMetadata.siteUrl + logoImage.childImageSharp.fluid.src}`;
  const siteTitle = title;
  const siteDescription = description || `${site.siteMetadata.description}`;
  const twitterCreator = "@" + site.siteMetadata.twitterUsername;
  const pageMicroData = site.siteMetadata.microData;
  const schemaOrgForPage = schemaOrg(
    siteUrl || pageMicroData.siteUrl,
    pageMicroData.facebookLink,
    pageMicroData.linkedinLink,
    pageMicroData.twitterLink,
    pageMicroData.mediumLink,
    pageMicroData.knowsLanguage,
    pageMicroData.areaServed,
    siteDescription || pageMicroData.defaultDescription,
    pageMicroData.name,
    pageMicroData.addressLocality1,
    pageMicroData.addressPostCode1,
    pageMicroData.addressStreet1,
    pageMicroData.addressLocality2,
    pageMicroData.addressPostCode2,
    pageMicroData.addressStreet2,
    pageMicroData.contactPhone,
    pageMicroData.email,
    imageUrl || pageMicroData.imageUrl,
    imageUrl || pageMicroData.imageUrl,
    pageMicroData.page1,
    pageMicroData.page2,
    pageMicroData.page3,
    pageMicroData.page4,
    pageMicroData.page5,
    pageMicroData.page6
  );

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          charSet: "UTF-8",
        },
        {
          viewport: "width=device-width,initial-scale=1",
        },
        {
          "format-detection": "telephone=no",
        },
        // Search Engine
        {
          name: `name`,
          content: siteTitle,
        },
        {
          name: `description`,
          content: siteDescription,
        },
        {
          name: `image`,
          content: imageUrl,
        },
        // Google
        {
          itemprop: `name`,
          content: siteTitle,
        },
        {
          itemprop: `description`,
          content: siteDescription,
        },
        {
          itemprop: `image`,
          content: imageUrl,
        },
        // Open Graph: Facebook, Linkedin, Instagram, Pinterest
        {
          name: `og:title`,
          property: siteTitle,
        },
        {
          name: `og:site_name`,
          property: siteTitle,
        },
        {
          name: `og:description`,
          property: siteDescription,
        },
        {
          name: `og:type`,
          property: `website`,
        },
        {
          name: `og:url`,
          property: siteUrl,
        },
        {
          name: `og:image`,
          property: imageUrl,
        },
        {
          name: `og:locale`,
          property: locale,
        },
        // Twitter
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: twitterCreator,
        },
        {
          name: `twitter:site`,
          content: twitterCreator,
        },
        {
          name: `twitter:title`,
          content: siteTitle,
        },
        {
          name: `twitter:description`,
          content: siteDescription,
        },
        {
          name: `twitter:image`,
          content: imageUrl,
        },
        {
          name: `twitter:image:alt`,
          content: siteDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      {schemaOrgForPage && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgForPage)}
        </script>
      )}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  pathname: "",
  locale: "en_NZ",
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};

export default SEO;
