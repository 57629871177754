import React from "react";
import { ThemeProvider } from "@mechatronick/datum-design-system";
import BG from "../components/BG";

export default ({ bg, children }) => (
  <ThemeProvider webfonts>
    {bg && <BG color={bg} />}
    {children}
  </ThemeProvider>
);
