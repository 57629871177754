import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Loadable from "react-loadable";
import Loading from "../components/Loading";
import FakeContent from "../components/software/FakeContent";

const Nav = Loadable({
  loader: () => import("../components/SharedComponents/Nav"),
  loading: FakeContent,
});
const Header = Loadable({
  loader: () => import("../components/software/Header"),
  loading: Loading,
});
const SoftwareDevelopment = Loadable({
  loader: () => import("../components/software/SoftwareDevelopment"),
  loading: Loading,
});
const TheProcess = Loadable({
  loader: () => import("../components/software/TheProcess"),
  loading: Loading,
});
const OurDeliveryServices = Loadable({
  loader: () => import("../components/software/OurDeliveryServices"),
  loading: Loading,
});
const Platform = Loadable({
  loader: () => import("../components/SharedComponents/Platform"),
  loading: Loading,
});
const OperatingSystems = Loadable({
  loader: () => import("../components/SharedComponents/OperatingSystems"),
  loading: Loading,
});
const DataMachineLearning = Loadable({
  loader: () => import("../components/SharedComponents/DataMachineLearning"),
  loading: Loading,
});
const Technologies = Loadable({
  loader: () => import("../components/SharedComponents/Technologies"),
  loading: Loading,
});
const LangAndFrameworks = Loadable({
  loader: () => import("../components/SharedComponents/LangAndFrameworks"),
  loading: Loading,
});
const ContactForm = Loadable({
  loader: () => import("../components/SharedComponents/ContactFormH1"),
  loading: Loading,
});
const SubscribeFooter = Loadable({
  loader: () => import("../components/SharedComponents/SubscribeFooter"),
  loading: Loading,
});
const Footer = Loadable({
  loader: () => import("../components/SharedComponents/Footer"),
  loading: Loading,
});

export default () => {
  return (
    <Layout>
      <SEO
        title={"Software Development | Datum Consulting NZ"}
        description={
          "Our Software Development services include " +
          "planning and delivery of UX/UI design, " +
          "fullstack web development, mobile application, eCommerce, " +
          "DevOps, automation testing, software maintenance."
        }
        keywords={[
          `aws`,
          `azure`,
          `software development`,
          `digital transformation`,
          `software delivery`,
          `enterprise support`,
          `it consulting`,
          `user experience`,
          `user interface`,
          `design`,
          `web development`,
          `android`,
          `ios`,
          `mobile app`,
          `devop`,
          `automated testing`,
          `big data`,
          "eCommerce",
        ]}
        pathname="software-development"
      />
      <Nav />
      <Header />
      <SoftwareDevelopment />
      <TheProcess />
      <OurDeliveryServices />
      <Platform />
      <OperatingSystems />
      <Technologies />
      <LangAndFrameworks />
      <DataMachineLearning />
      <ContactForm />
      <SubscribeFooter />
      <Footer />
    </Layout>
  );
};
