import React from "react";
import {SEO_TYPE} from "../../constant";
import SeoElement from "../SeoElement"

const data = [
    {
        type: SEO_TYPE.H1,
        heading: "Software development with Datum Consulting NZ"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "To deliver a great digital experience across your digital channels, " +
            "you need to standardize your brand " +
            "appearance, continuously release new enhancement, prioritize " +
            "continuous delivery, integrate real user " +
            "feedback, collect data and improve based on new insights."
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Datum Consulting can help by executing end-to-end technology plan, " +
            "implementation, quality assurance, management and support of " +
            "high quality digital products."
    },
    {
        type: SEO_TYPE.H3,
        heading: "WEBSITE"
    },
    {
        type: SEO_TYPE.H3,
        heading: "MOBILE APPLICATION"
    },
    {
        type: SEO_TYPE.H3,
        heading: "ECOMMERCE"
    },
    {
        type: SEO_TYPE.H3,
        heading: "BRAND AND EXPERIENCE DESIGN"
    },
    {
        type: SEO_TYPE.INTERNAL_LINK,
        to: "/contact/",
        title: "TALK ABOUT SOFTWARE WITH US",
        label: "TALK ABOUT SOFTWARE WITH US"
    },
    // the process
    {
        type: SEO_TYPE.H2,
        heading: "Our software delivery process"
    },
    // the process: Step 1: Solution discovery
    {
        type: SEO_TYPE.H3,
        heading: "Step 1: Solution discovery"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "We will work hand-in-hand with your team to " +
            "understand the desired outcome, specify the " +
            "scope, architect the solution, outline the risks " +
            "and limitations to draft the first development " +
            "roadmap. Resource scheduling, key milestone " +
            "and timeline will be specified."
    },
    {
        type: SEO_TYPE.H4,
        heading: "Deliverable"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Solution Document", "Roadmap"]
    },
    // the process: Step 2: Proof of concept
    {
        type: SEO_TYPE.H3,
        heading: "Step 2: Proof of concept"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "In this phase, we model the product you " +
            "want to build using the architecture outlined in " +
            "the earlier and prepare your business case. All " +
            "feedback and finding will be captured and " +
            "brought forward to the project."
    },
    {
        type: SEO_TYPE.H4,
        heading: "Deliverable"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Design document", "Sharable Code", "Validated hypothesis"]
    },
    // the process: Step 3: Iterative development
    {
        type: SEO_TYPE.H3,
        heading: "Step 3: Iterative development"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "The development team will work in 2 week agile" +
            "spins without scope creep and gradually" +
            "transfer the knowledge from prior phases. Our " +
            "delivery manager will work directly with your" +
            "team and take ownership for the project " +
            "success.",
    },
    {
        type: SEO_TYPE.H4,
        heading: "Deliverable"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Design document", "Live product",
            "New features", "Product enhance", "Integration"]
    },
    // the process: Step 4: Delivery and transfer
    {
        type: SEO_TYPE.H3,
        heading: "Step 2: Proof of concept"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Once our team has completed work on the " +
            "project, we will provide the final deliverable, " +
            "release project resources, and measure the " +
            "success of the project."
    },
    {
        type: SEO_TYPE.H4,
        heading: "Deliverable"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Source code", "Documentation", "Reviews"]
    },
    // the services
    {
        type: SEO_TYPE.H2,
        heading: "Our software development services"
    },
    // services offering: UX/UI design
    {
        type: SEO_TYPE.H3,
        heading: "UX/UI design"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Your brand deserve to have a consistent appearance across all digital " +
            "platform and channels. Our designers can help you perfect your " +
            "brand images:"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Brand identity, and brand style guide",
            "User experience and User interface design"]
    },
    // services offering: API and Integration
    {
        type: SEO_TYPE.H3,
        heading: "API and Integration"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "We understand that technology and business must be integrated to " +
            "generate new opportunities for your organization in the new digital " +
            "age. Our ability to develop API strategies, connect data backed " +
            "systems, and navigating complex hybrid platforms with legacy and " +
            "cloud system can help you leverage existing assets to innovate.",
    },
    // services offering: Full-stack Web Development
    {
        type: SEO_TYPE.H3,
        heading: "Full-stack Web Development"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Our people are experts in custom application development for a " +
            "variety of business sectors including retail, finance, and banking. " +
            "We offer expert project management and implementation for:",
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["CMS backed website development",
            "Low code development",
            "Web portal, intranet, dashboard, and web application",
            "Progressive Web Apps (PWAs)",
        ]
    },
    // services offering: Architecture and Design
    {
        type: SEO_TYPE.H3,
        heading: "UX/UI design"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Your brand deserve to have a consistent " +
            "appearance across all digital " +
            "platform and channels. Our designers can help you perfect your " +
            "brand images:"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Brand identity, and brand style guide",
            "User experience and User interface design"]
    },
    // services offering: Mobile Application
    {
        type: SEO_TYPE.H3,
        heading: "Mobile Application"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "We offer a wide range of mobile app development " +
            "services with latest" +
            "tools and techniques:",
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["iOS for iPhone and iPad",
            "Native Android apps", "Cross-platform Native"]
    },
    // services offering: Automation testing
    {
        type: SEO_TYPE.H3,
        heading: "Automation testing"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "QA service is an integral part in any of of our software development" +
            "project. During the course of development cycle, we will setup and" +
            "perform various types of testing to detect error and verify the" +
            "functionality of the end products." +
            "tests are within our capabilities."
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Unit tests",
            "Integration tests",
            "End-to-end tests",
            "Browser automation"]
    },
    // services offering: eCommerce
    {
        type: SEO_TYPE.H3,
        heading: "eCommerce"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Creative & Strategic eCommerce solutions. " +
            "We anchor your store in this competitive industry by growing sales, " +
            "developing websites, and delivering marketing on:"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["BigCommerce", "Shopify", "WooCommerce", "Magneto"]
    },
    // services offering: Big Data & Artificial Intelligence
    {
        type: SEO_TYPE.H3,
        heading: "Big Data & Artificial Intelligence"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Datum Consulting's interdisciplinary team of machine learning " +
            "engineer, data scientist, and big data engineer leverage latest tools " +
            "to solve previously impossible challenges in personalization, " +
            "recommendation, and and data driven decision making. Our " +
            "services include:",
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["Business Intelligence",
            "Machine learning & Deep learning",
            "Data lakes and Data warehouse",
            "Real-time streaming analytics"]
    },
    // services offering: DevOps
    {
        type: SEO_TYPE.H3,
        heading: "DevOps"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Our DevOps team has experience working with various hosting " +
            "providers and automation toolchains. We offer server setup, and " +
            "automation to support Continuous Delivery in all of our applications, " +
            "using the most up-to date tools to achieve high quality and rapid " +
            "software development. Services include:"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["CI/CD implementation",
            "Practice coaching"]
    },
    // services offering: Maintenance
    {
        type: SEO_TYPE.H3,
        heading: "Maintenance"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "After the project delivery phase, changes, bug fix, feature " +
            "enhancement, optimization are inevitable. We provide maintenance " +
            "services that can include any of the activities mentioned."
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: ["CI/CD implementation",
            "Practice coaching"]
    },
    // list of Platform
    {
        type: SEO_TYPE.H2,
        heading: "Cloud platform"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Amazon Web Services (AWS)"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Microsoft Azure"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Google Cloud Platform (GCP)"
    },
    {
        type: SEO_TYPE.H3,
        heading: "IBM Cloud"
    },
    {
        type: SEO_TYPE.H3,
        heading: "MuleSoft Anypoint"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Redhat OpenShift"
    },
    // list of Operating system
    {
        type: SEO_TYPE.H2,
        heading: "Operating systems"
    },
    {
        type: SEO_TYPE.H3,
        heading: "CentOS"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Redhat Linux"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Window Server"
    },
    // list of Tech
    {
        type: SEO_TYPE.H2,
        heading: "Technologies and tools"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Ansible"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Apache Ant"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Apache Maven"
    },
    {
        type: SEO_TYPE.H3,
        heading: "AWS Application Load Balancer"
    },
    {
        type: SEO_TYPE.H3,
        heading: "AWS Network Load Balancer"
    },
    {
        type: SEO_TYPE.H3,
        heading: "AWS CloudWatch"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Azure ActiveDirectory"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Cloud Conformity"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Cypress"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Datadog"
    },
    {
        type: SEO_TYPE.H3,
        heading: "DC/OS"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Docker"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Gradle"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Graphite"
    },
    {
        type: SEO_TYPE.H3,
        heading: "HAproxy"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Jenkins"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Jest"
    },
    {
        type: SEO_TYPE.H3,
        heading: "JMeter"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Kong"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Kubernetes"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Mesosphere"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Nagios"
    },
    {
        type: SEO_TYPE.H3,
        heading: "NginX"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Pacemaker"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Puppet"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Rancher"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Raygun"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Selenium"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Shinken"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Terraform"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Zabbix"
    },
    // list of Lang and Frameworks
    {
        type: SEO_TYPE.H2,
        heading: "Languages and Frameworks"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Angular.js"
    },
    {
        type: SEO_TYPE.H3,
        heading: "ASP .Net Core"
    },{
        type: SEO_TYPE.H3,
        heading: "Contentful"
    },
    {
        type: SEO_TYPE.H3,
        heading: "C#"
    },
    {
        type: SEO_TYPE.H3,
        heading: "D3.js"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Flutter"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Gatsby.js"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Golang"
    },
    {
        type: SEO_TYPE.H3,
        heading: "graphQL"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Hibernate"
    },
    {
        type: SEO_TYPE.H3,
        heading: "JavaEE"
    },
    {
        type: SEO_TYPE.H3,
        heading: "React.js"
    },
    {
        type: SEO_TYPE.H3,
        heading: "React Native"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Redux"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Serverless Framework"
    },
    {
        type: SEO_TYPE.H3,
        heading: "SilverStripe"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Spring"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Spring Boot"
    },
    {
        type: SEO_TYPE.H3,
        heading: "TypeScript"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Vue.js"
    },
    // list of Database and Machine Learning
    {
        type: SEO_TYPE.H2,
        heading: "Database, Queues, Big Data and Machine Learning tools"
    },
    {
        type: SEO_TYPE.H3,
        heading: "ActiveMQ"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Apache Airflow"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Apache Kafka"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Apache Spark"
    },
    {
        type: SEO_TYPE.H3,
        heading: "AWS DynamoDB"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Caffe 2"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Cassandra"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Cloudera"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Elasticsearch"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Hadoop"
    },
    {
        type: SEO_TYPE.H3,
        heading: "HazelCast"
    },
    {
        type: SEO_TYPE.H3,
        heading: "MariaDB"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Memcached"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Metabase"
    },
    {
        type: SEO_TYPE.H3,
        heading: "MongoDB"
    },
    {
        type: SEO_TYPE.H3,
        heading: "MySQL"
    },
    {
        type: SEO_TYPE.H3,
        heading: "OracleDB"
    },
    {
        type: SEO_TYPE.H3,
        heading: "PowerBI"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Presto"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Redis"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Segment"
    },
    {
        type: SEO_TYPE.H3,
        heading: "SparkMLlib"
    },
    {
        type: SEO_TYPE.H3,
        heading: "SQLServer"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Tableau"
    },
    {
        type: SEO_TYPE.H3,
        heading: "TensorFlow"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://linkedin.com/company/datumconsultingnz",
        title: "Linkedin",
        label: "Linkedin"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://twitter.com/datumhqNZ",
        title: "Twitter",
        label: "Twitter"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://www.facebook.com/DatumConsultingNZ",
        title: "Facebook",
        label: "Facebook"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://medium.com/datum-consulting-nz",
        title: "Medium",
        label: "Medium"
    }
];

const FakeContent = () => (
    <div
        style={{
            backgroundColor: "white",
            color: "transparent",
            height: "100vh",
            width: "100vw",
            position: "relative",
            top: "0",
            left: "0",
        }}
    >
        {data.map((element, index) => <SeoElement key={index} {...element}/>)}
    </div>
);

export default FakeContent;